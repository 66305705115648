import { useState, useRef } from "react";
import { motion } from 'framer-motion';
import { loaderVariants, slideIn } from "../utils/motion";
import { FaTelegram } from "react-icons/fa";

import { AiFillTwitterCircle, AiOutlineCopy, AiOutlineArrowRight, AiOutlineTwitter, AiOutlineRight, AiOutlineWallet } from "react-icons/ai"
import Button from "../utils/Button";
import AnimeLogo from "../utils/AnimeLogo";
import CA from "../utils/Ca";
import AOS from 'aos';
import 'aos/dist/aos.css'


const Hero = ({ pryColor
    , secColor }
) => {
    AOS.init({ once: true });

    const [isCopied, setIsCopied] = useState(false);
    const handleCopied = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 5000)
    }

    const contractRef = useRef();

    const copyContractText = () => {
        const text = contractRef.current.textContent;
        navigator.clipboard.writeText(text);
        console.log('Text copied: ' + text);
        handleCopied();
    };

    const buttonStyle = "bg-[#fff] text-[#000] py-2 px-4 md:px-5 text-14px md:text-[18px] transform duration-300 hover:scale-110 rounded-md"

    return (<div className=" relative  w-[100vw]    flex  flex-col  mt-10 py-20 items-center justify-center pt-0 gap-5">

        <div className="md:[70%] mx-auto px-4 md:px-4" ><AnimeLogo imgUrl="logo.png" /> </div>

        <div className="w-full text-center">
            <div className="gap-4 p-10 text-[22px]  text-[#fff] flex flex-col glass_morphism duration-300 hover:scale-105 items-center justify-center font-bold">
                <div className="w-full md:w-[60%]">
                    <h1 className=" font-black text-[30px] md:text-[55px]">
                        flokiwifhat $FWIF
                    </h1>

                    <h1>
                        The OG dog is coming to the solana network.

                        but this time

                        wif his hat
                    </h1>
                    <a href="https://etherscan.io/address/#" >
                        <p className=" my-2 text-[14px] md:text-[18px]">ChNdg2ZbKXCcYFgBqZkkHpUVHxea1pAxaw1MwXdUMDMn</p>
                    </a>
                </div>

                <div>
                    <div className="grid  my-3 grid-cols-2  md:flex md:flex-row gap-3 md:gap-4 text-center font-bold"
                        data-aos="fade-right" data-aos-duration="250"
                        data-aos-delay="500"
                    >
                        <a className={`${buttonStyle}`} target="_blank" rel="noopener noreferrer"  href="https://twitter.com/flokiwifcoins">Twitter</a>
                        <a className={`${buttonStyle}`} target="_blank" rel="noopener noreferrer"  href="https://t.me/flokiwifcoin">Telegram</a>
                        <a className={`${buttonStyle}`} target="_blank" href="https://dexscreener.com/solana/ChNdg2ZbKXCcYFgBqZkkHpUVHxea1pAxaw1MwXdUMDMn" rel="noopener noreferrer" >Chart</a>
                        <a className="hidden md:block"  target="_blank" rel="noopener noreferrer" href="#"></a>
                        <a className={`${buttonStyle} `} rel="noopener noreferrer"  target="_blank" href="https://solscan.io/tx/44cqoNjeHcgvxHeV192JSnv3WdUqH3Ny4chV6MoQjystG7quydD5VRhzemzWMW3sE1gYrEtCeshQZf6dH7aGANtC">Burnt</a>
                    </div>
                </div>

            </div>
        </div>
    </div>);
}

export default Hero;